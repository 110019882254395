<template>
  <div class="exchange-detail">
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item> 人才交流 </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="computer-bg">
      <div class="basis-info">
        <p class="title-price">
          <span>{{ info.job_name }}</span>
          <span class="price"
            >{{ info.salary_low }}-{{ info.salary_hign }}</span
          >
        </p>
        <p class="info">
          {{ info.province }}{{ info.county }} | {{ info.education }} |
          {{ info.experience }}
        </p>
      </div>
    </div>
    <div class="detail-content">
      <div class="content-item">
        <h5 class="item-title">职位描述</h5>
        <div>
          <h6 class="item-second-title">岗位职责:</h6>
          <p class="item-describe" v-html="info.job_require"></p>
          <h6 class="item-second-title">任职资格:</h6>
          <p class="item-describe" v-html="info.experience"></p>
        </div>
      </div>
      <div class="content-item">
        <h5 class="item-title">公司介绍</h5>
        <div>
          <p class="item-describe" v-html="info.introduct"></p>
        </div>
      </div>
      <div class="content-item">
        <h5 class="item-title">工商信息</h5>
        <span class="item-describe" v-html="info.business_info"></span>
        <!-- <ul>
          <li>
            <span class="item-describe-title">
              法定代表人：
              <span class="item-describe">张晓峰</span>
            </span>
            <span class="item-describe-title" style="margin-left: 15px">
              注册资本：
              <span class="item-describe">100万元</span>
            </span>
            <span class="item-describe-title" style="margin-left: 15px">
              成立时间：
              <span class="item-describe">2022年5月25日</span>
            </span>
          </li>
          <li>
            <span class="item-describe-title">
              企业类型：
              <span class="item-describe">其他有限责任公司</span>
            </span>
          </li>
          <li>
            <span class="item-describe-title">
              经营状态：
              <span class="item-describe">开业</span>
            </span>
          </li>
          <li>
            <span class="item-describe-title">
              注册地址：
              <span class="item-describe">{{ info.address }}</span>
            </span>
          </li>
          <li>
            <span class="item-describe-title">
              统一社会信用代码：
              <span class="item-describe">910252542126775</span>
            </span>
          </li>
          <li>
            <span class="item-describe-title">
              经营范围：
              <span class="item-describe">910252542126775</span>
            </span>
          </li>
        </ul> -->
      </div>
      <div class="content-item">
        <h5 class="item-title">工作地址</h5>
        <div>
          <p class="item-describe">{{ info.address }}</p>
        </div>
      </div>
      <div class="content-item">
        <h5 class="item-title">企业其他职位</h5>
        <ul class="list">
          <li class="list-item" v-for="talent in list" :key="talent.id">
            <div class="item-info">
              <div class="info-title">
                <span class="title-pos">{{ talent.job_name }}</span>
                <span class="title-time">{{ talent.times }} 发布</span>
              </div>
              <div class="info-msg">
                <div class="price-require">
                  <span class="price"
                    >{{ talent.salary_low }}-{{ talent.salary_hign }}</span
                  >
                  <span class="require"
                    >{{ talent.province }}{{ talent.county }} |
                    {{ talent.education }} | {{ talent.experience }}</span
                  >
                </div>
              </div>
            </div>
            <div class="item-intro">
              <div class="intro-company">
                <span>{{ talent.company_name }}</span>
              </div>
              <div class="intro-msg">
                <span
                  >{{ talent.company_type }}
                  <!-- {{
                    talent.financing == 1
                      ? "天使轮"
                      : talent.financing == 2
                      ? "A轮"
                      : talent.financing == 3
                      ? "B轮"
                      : talent.financing == 4
                      ? "C轮"
                      : talent.financing == 5
                      ? "D轮及以上"
                      : "已上市"
                  }} -->
                  | {{ talent.scale }}人</span
                >
              </div>
            </div>
            <div class="item-button">
              <button @click="changeExid(talent.id)">查看职位</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {},
      list: [],
    };
  },

  components: {},

  computed: {},
  mounted() {
    this.getTalentDetail();
  },
  methods: {
    async getTalentDetail() {
      let { data } = await this.axios.get(
        `/index/recruit_detail?id=${this.$route.query.exid}`
      );
      this.info = data.list;
      this.list = data.other_job;
    },
    async changeExid(id) {
      this.$router.push({ query: { exid: id } });
      this.$router.go(0);
      return;
      this.$route.query.exid = id;
      let { data } = await this.axios.get(`/index/recruit_detail?id=${id}`);
      this.info = data.list;
      this.list = data.other_job;
    },
  },
};
</script>
<style lang="scss" scoped>
.exchange-detail {
  padding-bottom: 50px;
  .computer-bg {
    height: 260px;
    width: 100%;
    background: url(../assets/images/computer_bg.png) no-repeat center;
    .basis-info {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 50px;
      p {
        margin: 0px;
        color: #fff;
      }
      .title-price {
        font-size: 24px;
        .price {
          color: #fe8720;
          margin-left: 30px;
        }
      }
      .info {
        margin-top: 40px;
      }
    }
  }
  .detail-content {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    p {
      margin: 15px 0;
    }

    .content-item {
      .item-title {
        font-size: 18px;
        line-height: 1;
        color: #fe8720;
        font-weight: normal;
        position: relative;
        left: 10px;
        margin: 10px 0;
        &::before {
          content: "";
          display: block;
          height: 100%;
          width: 3px;
          border-radius: 50px;
          background-color: rgba($color: #fe8720, $alpha: 0.9);
          position: absolute;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .item-second-title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
        margin: 15px 0;
      }
      .item-describe-title {
        color: #666;
      }
      .item-describe {
        color: #333;
        font-size: 15px;
        line-height: 2;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-top: 8px;
        }
      }
      .list {
        $grey: #666;
        $orange: #fe8720;
        list-style: none;
        margin: 0;
        margin-top: 20px;
        padding: 0;

        .list-item {
          width: 100%;
          margin: 0 auto;
          margin-top: 10px;
          background-color: #fff;
          border-radius: 3px;
          padding: 40px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid rgba($color: #d0d0d0, $alpha: 0.34);
          user-select: none;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 10%);
          }
          .item-info {
            flex: 2;

            .info-title {
              .title-pos {
                font-size: 20px;
              }
              .title-time {
                font-size: 14px;
                color: #999;
                margin-left: 20px;
              }
            }
            .info-msg {
              margin-top: 20px;
              .price-require {
                .price {
                  color: $orange;
                  font-size: 18px;
                }
                .require {
                  font-size: 14px;
                  color: $grey;
                  margin-left: 35px;
                }
              }
            }
          }
          .item-intro {
            flex: 1;
            .intro-company {
              font-size: 16px;
            }
            .intro-msg {
              color: $grey;
              margin-top: 20px;
            }
          }
          .item-button {
            flex: 1;
            text-align: right;
            button {
              width: 100px;
              height: 30px;
              border-radius: 15px;
              color: #fff;
              background-color: $orange;
              border: 0;
              outline: 0;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
